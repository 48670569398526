import { ShipitUserParameterKey } from '@/views/shipit-administration/enums/shipit-administration';

type User = {
    oid: string;
    name: string;
    authenticationType: string;
    roles: string[];
    isActive: boolean;
    responsibleRegion: number;
    farms: FarmType[];
    parameters: UserParameter[];
    shippers: {
        shippers: UserShipper[];
    };
};

type UserRequestParameter = {
    value: string;
    parameterKey: string;
};

enum FarmType {
    SANDBOX = 'sandbox',
    MAIN = 'main'
}

type UserRequest = {
    name: string;
    oid: string;
    responsibleRegion?: number;
    roles: string[];
    authenticationType: string;
    contactId: string[];
    farms: FarmType[];
    isActive: boolean;
    parameters: UserRequestParameter[];
};

type UserParameter = {
    value: string;
    key: string;
};

type DefaultParameter = {
    parameter_key: ShipitUserParameterKey;
    default_value: string;
};

type UserShipper = {
    contactId: string;
    name: string;
    customerId: string;
};

type GetShipitUsersRequest = {
    name?: string;
    contactId?: string;
    responsibleRegion?: string;
    page: number;
    limit: number;
    sort: keyof User;
};

export { type User, type UserParameter, type UserShipper, type UserRequest, type GetShipitUsersRequest, type DefaultParameter, FarmType };
